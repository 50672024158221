import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { productSelector } from '../../../../../../store/products/products.selectors';
import useForm from '../../../../../../utils/useForm';
import { productsActions } from '../../../../../../store/products/products.reducers';
import styles from '../create-product-page.module.scss';
import { Button, Checkbox, Dropdown, Fieldset, TextField } from '@anatoscope/circlestorybook';
import { CreateProductNavProps } from '../CreateProductPage';
import {
  areCommonTypesLoadingSelector,
  stumpModesDropdownSelector,
  teethModesDropdownSelector
} from '../../../../../../store/common-types/common-types.selectors';
import { StringObject } from '../../../../../../models/common';
import { CheckboxStringItem } from '../../../../../../models/form';
import {
  areCircleCadVersionsLoading,
  activeCircleCadVersionsDroprownSelector
} from '../../../../../../store/circle-cad-versions/circle-cad-versions.selectors';
import { InjectionModeEnum } from '../../../../../../enum/product.enum';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';

const ConfigurationForm = ({ nextStepCallback, previousStepCallback }: CreateProductNavProps) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const product = useAppSelector(productSelector);
  const areCommonTypesLoading = useAppSelector(areCommonTypesLoadingSelector);
  const stumpModesDropdownData = useAppSelector(stumpModesDropdownSelector);
  const teethModesDropdownData = useAppSelector(teethModesDropdownSelector);
  const isLoadingVersions = useAppSelector(areCircleCadVersionsLoading);
  const circleCadVersions = useAppSelector(activeCircleCadVersionsDroprownSelector);
  const productProportionalBilling = product.proportionalBilling;

  // Form configuration
  const submitCallback = () => {
    dispatch(
      productsActions.setProduct({
        ...product,
        ...values,
        proportionalBilling: proportionalBilling[0].isChecked,
        injectionMode: injectionMode[0].isChecked
          ? InjectionModeEnum.SELECTABLE
          : InjectionModeEnum.NONE,
        isChairSide: isChairSide[0].isChecked
      })
    );
    nextStepCallback();
  };

  const { values, handleChange, handleBlur, handleSubmit, handleSelect, handleCheck, errors } =
    useForm(
      {
        proportionalBilling: [
          {
            value: 'proportionalBilling',
            isChecked: productProportionalBilling || false
          }
        ],
        circleCadVersion: product?.circleCadVersion || '',
        circleCadProductType: product?.circleCadProductType || '',
        stumpMode: product.stumpMode,
        teethMode: product.teethMode,
        injectionMode: [
          {
            value: 'injectionMode',
            isChecked: product.injectionMode === InjectionModeEnum.SELECTABLE
          }
        ],
        isChairSide: [
          {
            value: 'isChairSide',
            isChecked: product.isChairSide || false
          }
        ]
      },
      submitCallback,
      () => {
        const newErrors: StringObject = {};
        if (values['circleCadVersion'] && !values['circleCadProductType']) {
          newErrors['circleCadProductType'] = 'empty';
        }
        ['stumpMode', 'teethMode'].forEach((key) => {
          if (!values[key]) {
            newErrors[key] = 'empty';
          }
        });
        return newErrors;
      }
    );

  const proportionalBilling = values.proportionalBilling as CheckboxStringItem[];
  const injectionMode = values.injectionMode as CheckboxStringItem[];
  const isChairSide = values.isChairSide as CheckboxStringItem[];
  return (
    <form onSubmit={handleSubmit} className={styles['create-product-page__form']}>
      <Fieldset size="m" className={styles['create-product-page__form__fieldset']}>
        <Dropdown
          id="circleCadVersion"
          name="circleCadVersion"
          data={isLoadingVersions ? [] : [circleCadVersions]}
          value={values.circleCadVersion}
          placeholder={t('form.select', { ns: 'common' })}
          className={styles['create-product-page__form__fieldset__sub-fieldset']}
          onChange={(newValue: string) => {
            handleSelect(newValue, 'circleCadVersion');
            if (!newValue) {
              handleSelect('', 'circleCadProductType');
            }
          }}
          label={t('products.productForm.circleCadVersion.label')}
          helperText={
            errors.circleCadVersion
              ? t(`products.productForm.circleCadVersion.${errors.circleCadVersion}`)
              : t('products.productForm.circleCadVersion.helper')
          }
          variant={
            errors.circleCadVersion ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
          }></Dropdown>

        {values.circleCadVersion && (
          <TextField
            id="circleCadProductType"
            name="circleCadProductType"
            value={values.circleCadProductType}
            label={t('products.productForm.circleCadProductType.label')}
            placeholder={t('products.productForm.circleCadProductType.placeholder')}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={
              errors.imageUrl
                ? t('products.productForm.circleCadProductType.error')
                : t('products.productForm.circleCadProductType.helper')
            }
            className={styles['create-product-page__form__fieldset__sub-fieldset']}
            variant={
              errors.circleCadProductType ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
            }></TextField>
        )}

        <div className={styles['create-product-page__form__fieldset__checkbox']}>
          <Checkbox
            data-cy="checkbox-is-chairside"
            label={t('products.productForm.isChairSide.label')}
            isChecked={isChairSide[0].isChecked}
            onClick={() => handleCheck('isChairSide', 'isChairSide')}
          />
        </div>

        <div>
          <Dropdown
            data={areCommonTypesLoading ? [] : [teethModesDropdownData]}
            value={values.teethMode}
            isLoading={areCommonTypesLoading}
            label={t('products.productForm.teethMode.label')}
            placeholder={t('form.select', { ns: 'common' })}
            className={styles['create-product-page__form__fieldset__sub-fieldset']}
            onChange={(newValue: string) => handleSelect(newValue, 'teethMode')}
            variant={errors.teethMode ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            helperText={
              errors.teethMode
                ? t(`products.productForm.teethMode.${errors.teethMode}`)
                : t('products.productForm.teethMode.helper')
            }></Dropdown>
          <Dropdown
            data={areCommonTypesLoading ? [] : [stumpModesDropdownData]}
            value={values.stumpMode}
            isLoading={areCommonTypesLoading}
            label={t('products.productForm.stumpMode.label')}
            placeholder={t('form.select', { ns: 'common' })}
            onChange={(newValue: string) => handleSelect(newValue, 'stumpMode')}
            variant={errors.stumpMode ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-product-page__form__fieldset__sub-fieldset']}
            helperText={
              errors.stumpMode
                ? t(`products.productForm.stumpMode.${errors.stumpMode}`)
                : t('products.productForm.stumpMode.helper')
            }></Dropdown>
          <div className={styles['create-product-page__form__fieldset__checkbox']}>
            <Checkbox
              data-cy="checkbox-injection-mode"
              label={t('products.productForm.injectionMode.label')}
              isChecked={injectionMode[0].isChecked}
              onClick={() => handleCheck('injectionMode', 'injectionMode')}
            />
          </div>
          <div className={styles['create-product-page__form__fieldset__checkbox__container']}>
            <div className={styles['create-product-page__form__fieldset__checkbox']}>
              <Checkbox
                data-cy="checkbox-proportional-billing"
                label={t('products.productForm.proportionalBilling.label')}
                helperText={t('products.productForm.proportionalBilling.helper')}
                isChecked={proportionalBilling[0].isChecked}
                onClick={() => handleCheck('proportionalBilling', 'proportionalBilling')}
              />
            </div>
          </div>
        </div>
      </Fieldset>
      <div className="form__buttons">
        <Button
          label={t('action.previous', { ns: 'common' })}
          category="secondary"
          onClick={previousStepCallback}
          iconLeft="fa-chevron-left"
        />
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};

export default ConfigurationForm;
