import { AnyAction, configureStore, createAction, Reducer } from '@reduxjs/toolkit';
import { AuthenticationReducer, UsersReducer } from '../models/user';
import { authSlice } from './auth/auth.reducers';
import { usersSlice } from './users/users.reducers';
import { establishmentsSlice } from './establishment/establishment.reducer';
import { EstablishmentsReducer } from '../models/establishment';
import { FeedbackReducer } from '../models/feedback';
import { feedbackSlice } from './feedback/feedback.reducer';
import { ProductsReducer } from '../models/product';
import { productsSlice } from './products/products.reducers';
import { ComponentsReducer } from '../models/component';
import { CommonTypesReducer } from '../models/common-types';
import { componentsSlice } from './components/components.reducers';
import { commonTypesSlice } from './common-types/common-types.reducers';
import { datagridsSettingsSlice } from './datagrids-settings/datagrids-settings.reducers';
import { DatagridsSettingsReducer } from '../models/datagrid';
import { circleCadVersionsReducer } from '../models/circle-cad-version';
import { circleCadVersionsSlice } from './circle-cad-versions/circle-cad-versions.reducers';
import { api } from '../services/api';
import { rtkQueryErrorLogger } from '../services/errors.services';

interface ReducerInterface {
  authenticationState: Reducer<AuthenticationReducer, AnyAction>;
  userState: Reducer<UsersReducer, AnyAction>;
  feedbackState: Reducer<FeedbackReducer, AnyAction>;
  establishmentsState: Reducer<EstablishmentsReducer, AnyAction>;
  productState: Reducer<ProductsReducer, AnyAction>;
  componentState: Reducer<ComponentsReducer, AnyAction>;
  commonTypeState: Reducer<CommonTypesReducer, AnyAction>;
  circleCadVersionsState: Reducer<circleCadVersionsReducer, AnyAction>;
  datagridsSettingsState: Reducer<DatagridsSettingsReducer, AnyAction>;
}

const reducer: ReducerInterface = {
  authenticationState: authSlice.reducer,
  userState: usersSlice.reducer,
  feedbackState: feedbackSlice.reducer,
  establishmentsState: establishmentsSlice.reducer,
  productState: productsSlice.reducer,
  componentState: componentsSlice.reducer,
  commonTypeState: commonTypesSlice.reducer,
  circleCadVersionsState: circleCadVersionsSlice.reducer,
  datagridsSettingsState: datagridsSettingsSlice.reducer
};

const store = configureStore({
  reducer: {
    ...reducer,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(api.middleware)
      .concat(rtkQueryErrorLogger)
});

export const resetAll = createAction('RESET_ALL');

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
