import i18next from 'i18next';
import { capitalize } from '../../../utils/utils';

export const getLocalizedProperty = (property: string): string => {
  switch (property) {
    case 'name':
    case 'label':
      // This adds the locale after the filter name (for example, label -> labelFr or labelEn)
      return `label${capitalize(i18next.language)}`;
    case 'products':
      return `products${capitalize(i18next.language)}`;
    default:
      return property;
  }
};
