import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { productSelector } from '../../../../../../store/products/products.selectors';
import useForm from '../../../../../../utils/useForm';
import { productsActions } from '../../../../../../store/products/products.reducers';
import styles from '../create-product-page.module.scss';
import { Button, Checkbox, Fieldset } from '@anatoscope/circlestorybook';
import { CreateProductNavProps } from '../CreateProductPage';
import { ProductRule } from '../../../../../../models/product';
import { CheckboxStringItem } from '../../../../../../models/form';

const ProductRuleForm = ({ nextStepCallback, previousStepCallback }: CreateProductNavProps) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const product = useAppSelector(productSelector);
  const productRule = product.productRule;

  // Form configuration
  const submitCallback = () => {
    const newProductRule: ProductRule = {
      allowSameProductInOrder: allowSameProductInOrderProp[0].isChecked,
      allowSameProductOnArch: allowSameProductOnArchProp[0].isChecked,
      allowSaneTeethInArch: !removeSaneTeethInArchProp[0].isChecked
    };

    dispatch(
      productsActions.setProduct({
        ...product,
        productRule: newProductRule
      })
    );
    nextStepCallback();
  };
  const customHandleCheck = (name: string) => {
    const currentProp = values[name] as CheckboxStringItem[];
    const allowSameProductOnArchProp = values.allowSameProductOnArch as CheckboxStringItem[];

    // If there can't be twice the same product in order, there can't be twice the same product on arch
    // If there can't be twice the same product on arch, there is nothing to merge
    if (currentProp[0].isChecked) {
      if (name === 'allowSameProductInOrder') {
        allowSameProductOnArchProp[0].isChecked = false;
      }
    }
    // Call default form handleCheck to update values
    handleCheck(name, name);
  };

  const { values, handleSubmit, handleCheck } = useForm(
    {
      removeSaneTeethInArch: [
        {
          value: 'removeSaneTeethInArch',
          isChecked: productRule ? !productRule.allowSaneTeethInArch : false
        }
      ],
      allowSameProductInOrder: [
        {
          value: 'allowSameProductInOrder',
          isChecked: productRule ? productRule.allowSameProductInOrder : false
        }
      ],
      allowSameProductOnArch: [
        {
          value: 'allowSameProductOnArch',
          isChecked: productRule ? productRule.allowSameProductOnArch : false
        }
      ]
    },
    submitCallback
  );

  const allowSameProductInOrderProp = values.allowSameProductInOrder as CheckboxStringItem[];
  const allowSameProductOnArchProp = values.allowSameProductOnArch as CheckboxStringItem[];
  const removeSaneTeethInArchProp = values.removeSaneTeethInArch as CheckboxStringItem[];

  // Return form
  return (
    <form onSubmit={handleSubmit} className={styles['create-product-page__form']}>
      <Fieldset size="m" className={styles['create-product-page__form__fieldset']}>
        <Checkbox
          label={t('products.productForm.allowSameProductInOrder.label')}
          helperText={t('products.productForm.allowSameProductInOrder.helper')}
          isChecked={allowSameProductInOrderProp[0].isChecked}
          onClick={() => customHandleCheck('allowSameProductInOrder')}
          className={styles['create-product-page__form__fieldset__checkbox']}
        />
        <Checkbox
          label={t('products.productForm.allowSameProductOnArch.label')}
          helperText={t('products.productForm.allowSameProductOnArch.helper')}
          isDisabled={!allowSameProductInOrderProp[0].isChecked}
          isChecked={allowSameProductOnArchProp[0].isChecked}
          onClick={() => customHandleCheck('allowSameProductOnArch')}
          className={[
            styles['create-product-page__form__fieldset__checkbox'],
            styles['create-product-page__form__fieldset__checkbox--level-2']
          ].join(' ')}
        />
        <div className={styles['create-product-page__form__fieldset__checkbox']}>
          <Checkbox
            label={t('products.productForm.removeSaneTeethInArch.label')}
            helperText={t('products.productForm.removeSaneTeethInArch.helper')}
            isChecked={removeSaneTeethInArchProp[0].isChecked}
            onClick={() => customHandleCheck('removeSaneTeethInArch')}
          />
        </div>
      </Fieldset>
      <div className="form__buttons">
        <Button
          label={t('action.previous', { ns: 'common' })}
          category="secondary"
          onClick={previousStepCallback}
          iconLeft="fa-chevron-left"
        />
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};

export default ProductRuleForm;
