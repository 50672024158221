// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,700;1,100;1,400;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w1zaqwYKEpiD9hjiDUEX {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/utils/checkbox-filter.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AAAF","sourcesContent":["@use '@anatoscope/circlestorybook/dist/vars';\n\n.checkbox-column-header {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-column-header": `w1zaqwYKEpiD9hjiDUEX`
};
export default ___CSS_LOADER_EXPORT___;
