import { ProductsReducer } from '../../models/product';

interface ReducerState {
  productState: ProductsReducer;
}

export const productSelector = (state: ReducerState) => state.productState.product;

export const productDetailsSelector = (state: ReducerState) => state.productState?.productDetails;

export const isProductLoadingSelector = (state: ReducerState) =>
  state.productState?.isProductLoading;

export const activeProductsSelector = (state: ReducerState) => state.productState?.activeProducts;

export const areActiveProductsLoadingSelector = (state: ReducerState) =>
  state.productState?.areActiveProductsLoading;
