// To avoid duplicating an enum with all the steps and an enum with the modelling steps,
// we've created several enums, including the modelling enum, which we merge and export all together.
// The reason we're creating three enums instead of two is to maintain the order of the steps

enum WorkFlowStepFirstPartEnum {
  SUBMITTING = 'submitting', // dentist is submitting the order
  VALIDATION = 'validation' // validate order by lab
}

export enum WorkflowModelingStepEnum {
  MODELING = 'modeling', // check products compatibility to CircleCAD
  MODELING_ANALYZE = 'modeling_analyze', // analyze patients files with CircleCAD (automatic)
  MODELING_PREPARE = 'modeling_prepare', // prepare patients files with CircleCAD (manual)
  MODELING_REGISTER = 'modeling_register', // register patients files with CircleCAD (automatic)
  MODELING_DESIGN = 'modeling_design', // design patients files with CircleCAD (manual)
  MODELING_EXPORT = 'modeling_export' // export manufacturing files with CircleCAD (automatic)
}

enum WorkFlowStepLastPartEnum {
  MANUFACTURING = 'manufacturing', // manufacturing products and components
  CONTROL = 'control', // control products and order
  DELIVERY = 'delivery', // deliver order to dentist
  DELIVERED = 'delivered' // order delivering or delivred. For the moment we are unable to differentiate between these two steps
}
export const WorkflowStepEnum = {
  ...WorkFlowStepFirstPartEnum,
  ...WorkflowModelingStepEnum,
  ...WorkFlowStepLastPartEnum
};

export type WorkflowStepEnum =
  | WorkFlowStepFirstPartEnum
  | WorkflowModelingStepEnum
  | WorkFlowStepLastPartEnum;
