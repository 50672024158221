import { createSelector } from '@reduxjs/toolkit';
import { circleCadVersionsReducer } from '../../models/circle-cad-version';
import { capitalize } from '../../utils/utils';

interface ReducerState {
  circleCadVersionsState: circleCadVersionsReducer;
}

export const circleCadVersionsSelector = (state: ReducerState) =>
  state.circleCadVersionsState.circleCadVersions;

export const areCircleCadVersionsLoading = (state: ReducerState) =>
  state.circleCadVersionsState.areCircleCadVersionsLoading;

export const activeCircleCadVersionsDroprownSelector = createSelector(
  circleCadVersionsSelector,
  (circleCadVersions) => {
    if (circleCadVersions) {
      return Object.values(circleCadVersions).map((circleCadVersion) => ({
        label: `${capitalize(circleCadVersion.label)} (${circleCadVersion.version})`,
        value: circleCadVersion.version
      }));
    }
    return [];
  }
);
