import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { feedbackActions } from '../store/feedback/feedback.reducer';
import { getMessageError } from '../utils/utils';
import { FeedBack } from '../models/feedback';
import { ErrorUIType } from '../enum/feedback';

/**
 * https://redux-toolkit.js.org/rtk-query/usage/error-handling#handling-errors-at-a-macro-level
 * https://gitlab.com/groups/anatoscope/circle/dev/platform/-/wikis/Functional-errors-management
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // Error message default
    const feedback: FeedBack = {
      type: 'danger',
      message: action.type.split('/').includes('auth')
        ? action.payload.message
        : getMessageError(action.payload)
    };

    if (action.payload.feedbackType === ErrorUIType.ATTENTION_BOX) {
      api.dispatch(feedbackActions.setAttentionBoxError(feedback));
    } else if (action.payload.feedbackType === ErrorUIType.FIELD) {
      api.dispatch(feedbackActions.setAttentionBoxError(feedback));
    } else {
      api.dispatch(feedbackActions.setToast(feedback));
    }
  }
  return next(action);
};
