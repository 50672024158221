import React from 'react';
import './dashboard-page.scss';

const DashboardPage = () => {
  return (
    <>
      <span>Bienvenue sur Circle Admin</span>
    </>
  );
};

export default DashboardPage;
