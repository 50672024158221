import React from 'react';
import '@inovua/reactdatagrid-enterprise/index.css';
import { SortDirection, SortReducer, TypeColWithNamePropertyPlatform } from '../../models/datagrid';
import stylesDataGrid from './datagrid-feature.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
  IColumn,
  TypeColumn,
  TypeColWithNameProperty
} from '@inovua/reactdatagrid-community/types/TypeColumn';
import { CellProps, TypeDataGridProps } from '@inovua/reactdatagrid-community/types';
import { getLocalizedProperty } from '../../pages/private/catalog-page/catalog';

const rowExpandColumn: IColumn = {
  header: '',
  headerProps: {
    className: stylesDataGrid['datagrid-feature__header']
  }
};

const renderRowDetailsExpandIcon = () => {
  return <FontAwesomeIcon icon={faChevronDown} />;
};

const renderRowDetailsCollapsedIcon = () => {
  return <FontAwesomeIcon icon={faChevronRight} />;
};

const DatagridFeature = ({
  columns,
  style,
  dataSource,
  className,
  renderRowDetails,
  rowExpandHeight,
  expandedRows,
  multiRowExpand,
  collapsedRows,
  onExpandedRowsChange,
  rowClassName,
  onReady,
  sortInfo,
  defaultSortInfo,
  onSortInfoChange,
  filterValue,
  onFilterValueChange,
  livePagination = true,
  pagination = true
}: Partial<TypeDataGridProps>) => {
  // Adding custom styles to each column header & cell
  columns.forEach((element: TypeColumn & { cellProps: CellProps }) => {
    // Sorry but when we used a typed library that not have any coherence with their docs...
    (element.headerProps as object) = { className: 'datagrid__header' };
    (element.cellProps as object) = { className: 'datagrid__cell' };
    (element.textAlign as string) = 'center';
  });

  return (
    <ReactDataGrid
      licenseKey="AppName=multi_app,Company=CircleAnatoscopeIntuitiveDesign,ExpiryDate=2024-09-27T00:00:00.000Z,LicenseDeveloperCount=7,LicenseType=multi_app,Ref=CircleAnatoscopeIntuitiveDesignLicenseRef,Z=1092652003-1280136484-14595002217877925101092652003-220672582"
      columns={columns}
      // We have to add this, not just a className, because it isn't applied to the same element (this applies to a children while the className applies to the datagrid element
      style={{ minHeight: 'calc(100dvh - 15rem)', ...style }}
      filterValue={filterValue}
      onFilterValueChange={onFilterValueChange}
      defaultLimit={25}
      showCellBorders={false}
      rowHeight={40}
      showColumnMenuTool={false}
      className={className}
      dataSource={dataSource}
      onReady={onReady}
      renderRowDetails={renderRowDetails}
      multiRowExpand={multiRowExpand}
      rowExpandHeight={rowExpandHeight}
      rowExpandColumn={rowExpandColumn}
      expandedRows={expandedRows}
      collapsedRows={collapsedRows}
      onExpandedRowsChange={onExpandedRowsChange}
      rowClassName={rowClassName}
      loadingText={i18next.t('loading', { ns: 'common' })}
      livePagination={livePagination}
      scrollThreshold={0.7}
      defaultSortInfo={defaultSortInfo}
      sortInfo={sortInfo}
      onSortInfoChange={onSortInfoChange}
      renderRowDetailsExpandIcon={renderRowDetailsExpandIcon}
      renderRowDetailsCollapsedIcon={renderRowDetailsCollapsedIcon}
      pagination={pagination}></ReactDataGrid>
  );
};

export const getColumnOptions = (
  name: string,
  header: string,
  defaultFlex?: number,
  sortable?: boolean, // true by default
  showColumnMenuTool?: boolean
): Partial<TypeColWithNameProperty> => {
  let column: Partial<TypeColWithNamePropertyPlatform> = {
    name: name,
    headerAlign: 'start',
    header: header,
    showColumnMenuTool: true,
    showColumnMenuToolOnHover: false,
    enableColumnFilterContextMenu: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left'
      }
    })
  };
  if (defaultFlex) {
    column = { ...column, defaultFlex: defaultFlex };
  }
  if (!sortable) {
    column = { ...column, sortable: sortable };
  }
  if (typeof showColumnMenuTool !== undefined) {
    column = { ...column, showColumnMenuTool: showColumnMenuTool };
  }
  return column;
};

// For now, this works for a list
// If we need to go further, we'll refactor it or rename it if needed
export const computeMasterDetailsHeight = (
  rowHeight: number,
  rowYMargin: number,
  detailsYMargin: number,
  propertiesToCheck: (object | string)[]
) => {
  const nbOfRows =
    1 + propertiesToCheck.reduce((number, property) => (property ? number + 1 : number), 0);

  return nbOfRows * (rowHeight + rowYMargin) + detailsYMargin;
};

export const getSort = (sortInfo: SortReducer | SortReducer[]) => {
  let urlSort = '';
  if (sortInfo) {
    const sortsArray = Array.isArray(sortInfo) ? sortInfo : [sortInfo];
    sortsArray.forEach(function (sort) {
      urlSort += `${urlSort}&sortBy=${getLocalizedProperty(sort?.name)}:${
        sort?.dir === SortDirection.ASC_AS_NB ? SortDirection.ASC : SortDirection.DESC
      }`;
    });
  }
  return urlSort;
};

export default DatagridFeature;
