import styles from '../create-component-page.module.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Fieldset, Dropdown, Button, TextField } from '@anatoscope/circlestorybook';
import useForm from '../../../../../../utils/useForm';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  componentTypeDropdownSelector,
  areCommonTypesLoadingSelector
} from '../../../../../../store/common-types/common-types.selectors';
import { componentsActions } from '../../../../../../store/components/components.reducers';
import { componentSelector } from '../../../../../../store/components/components.selectors';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';

type Props = {
  nextCallback: () => void;
};

const IdentificationForm = ({ nextCallback }: Props) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();

  const component = useAppSelector(componentSelector);
  const areCommonTypesLoading = useAppSelector(areCommonTypesLoadingSelector);
  const componentTypeDropdown = useAppSelector(componentTypeDropdownSelector);

  const saveComponentInfo = () => {
    dispatch(componentsActions.setComponent({ ...component, ...values }));
  };

  const onSubmit = () => {
    saveComponentInfo();
    nextCallback();
  };

  const { values, errors, handleSubmit, handleSelect, handleBlur, handleChange } = useForm(
    {
      componentType: component?.componentType,
      labelFr: component?.labelFr ?? '',
      labelEn: component?.labelEn ?? ''
    },
    onSubmit
  );

  return (
    <form onSubmit={handleSubmit} className={styles['create-component-page__form']}>
      <Fieldset size="m" className={styles['create-component-page__form__fieldset']}>
        <Dropdown
          label={t('components.componentForm.componentTypeInput.label')}
          data={areCommonTypesLoading ? [] : [componentTypeDropdown]}
          isLoading={areCommonTypesLoading}
          value={values.componentType}
          placeholder={t('components.componentForm.choose')}
          onChange={(newValue: number) => {
            dispatch(componentsActions.resetComponent());
            handleSelect(newValue, 'componentType');
          }}
          helperText={
            errors.componentType && t('components.componentForm.componentTypeInput.error')
          }
          variant={errors.componentType ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          className={styles['create-component-page__form__fieldset__input']}
        />
        <TextField
          value={values.labelFr}
          helperText={
            errors.labelFr
              ? t('components.componentForm.labelFrInput.error')
              : t('components.componentForm.labelFrInput.helperText')
          }
          id="labelFr"
          name="labelFr"
          placeholder="Châssis Titane"
          label={t('components.componentForm.labelFrInput.label')}
          onBlur={handleBlur}
          onChange={handleChange}
          className={styles['create-component-page__form__fieldset__input']}
          variant={errors.labelFr ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
        />
        <TextField
          value={values.labelEn}
          helperText={
            errors.labelEn
              ? t('components.componentForm.labelEnInput.error')
              : t('components.componentForm.labelEnInput.helperText')
          }
          className={styles['create-component-page__form__fieldset__input']}
          id="labelEn"
          name="labelEn"
          placeholder="Titanium frame"
          label={t('components.componentForm.labelEnInput.label')}
          onBlur={handleBlur}
          onChange={handleChange}
          variant={errors.labelEn ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
        />
      </Fieldset>
      <div className="form__submit-button form__submit-button--right">
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};

export default IdentificationForm;
