import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  BreadcrumbItem,
  BreadcrumbsBar,
  Button,
  Chips,
  Dialog,
  SideBarModal,
  Skeleton,
  Text
} from '@anatoscope/circlestorybook';
import styles from './component-details-page.module.scss';
import React, { useEffect, useState } from 'react';
import { capitalize, getMessageError } from '../../../../../utils/utils';
import {
  componentDetailSelector,
  isComponentLoadingSelector
} from '../../../../../store/components/components.selectors';
import { getValue } from '../components';
import { deactivateComponent } from '../../../../../services/components.services';
import {
  componentsActions,
  fetchComponentByIdAsync
} from '../../../../../store/components/components.reducers';
import { feedbackActions } from '../../../../../store/feedback/feedback.reducer';
import {
  Angulation,
  Aspect,
  Brand,
  Material,
  Shade,
  Shape,
  Structure
} from '../../../../../models/common-types';
import { getLocalizedProperty } from '../../catalog';
import moment from 'moment';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { ToastType } from '../../../../../enum/feedback';

const ComponentDetailsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const component = useAppSelector(componentDetailSelector);
  const isLoading = useAppSelector(isComponentLoadingSelector);
  const [isLoadingDeactivate, setIsLoadingDeactivate] = useState<boolean>(false);
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(fetchComponentByIdAsync(id))
      .unwrap()
      .catch((error) =>
        dispatch(
          feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
        )
      );
  }, []);

  const deactivate = async () => {
    setIsDialogOpened(false);
    if (component) {
      setIsLoadingDeactivate(true);
      deactivateComponent(component)
        .then(() => {
          // KISS: in the formatted date only seconds differ between back and front deleteAt.
          // The data will be updated when user will reload. It willl avoid us a new call just for a date.
          dispatch(
            componentsActions.setComponentDetails({
              ...component,
              deletedAt: new Date().toISOString()
            })
          );
          dispatch(
            feedbackActions.setToast({
              message: t('components.datagrid.deactivate.deactivated'),
              type: ToastType.SUCCESS
            })
          );
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        )
        .finally(() => {
          setIsLoadingDeactivate(false);
        });
    }
  };

  const displayDetailsElement = (
    commonType: Array<Material | Shade | Shape | Structure | Brand | Angulation | Aspect>,
    name?: string
  ) => {
    if (!commonType) {
      return;
    }

    if (isLoading) {
      return (
        <div className={styles['update-component-page__content__section__main__item']}>
          <Text
            color={ColorPropsEnum.GREY}
            label={t(`components.componentForm.${name}Input.label`)}
            className={styles['update-component-page__content__section__main__item__title']}></Text>
          <Skeleton type="text" />
        </div>
      );
    }

    const buildChip = (key: string, color: string, firstLabel: string, secondLabel: string) => (
      <Chips
        key={key}
        className={styles['update-component-page__content__section__main__item__chips']}
        color={color}
        firstLabel={firstLabel}
        secondLabel={secondLabel}
      />
    );

    return (
      <div className={styles['update-component-page__content__section__main__item']}>
        <Text
          color={ColorPropsEnum.GREY}
          label={t(`components.componentForm.${name}Input.label`)}
          className={styles['update-component-page__content__section__main__item__title']}></Text>
        {commonType.map((element) => {
          type ObjectKey = keyof typeof element;
          const propertyName =
            name === 'brand' ? ('brandName' as ObjectKey) : ('code' as ObjectKey);
          const value = element[propertyName].toString();
          const stratification =
            name === 'material' ? (element as Material).stratification : undefined;
          const color =
            name === 'shade'
              ? ColorPropsEnum[value.toUpperCase() as keyof typeof ColorPropsEnum]
              : ColorPropsEnum.FAMILY_GUARDS;
          const firstLabel = ['shade', 'brand'].includes(name) ? value : t(`${name}s.${value}`);
          const secondLabel = stratification
            ? capitalize(t(`materials.stratification.${stratification}`))
            : '';

          return buildChip(`${value}--${stratification}`, color, firstLabel, secondLabel);
        })}
      </div>
    );
  };

  const displayEnumDetailsElement = (commonType: string[], name?: string) => {
    return (
      commonType && (
        <div className={styles['update-component-page__content__section__main__item']}>
          <Text
            color={ColorPropsEnum.GREY}
            label={t(`components.componentForm.${name}Input.label`)}></Text>
          {isLoading ? (
            <Skeleton type="text" />
          ) : (
            commonType.map((value) => {
              return (
                <Chips
                  key={value}
                  className={styles['update-component-page__content__section__main__item__chips']}
                  color={ColorPropsEnum.FAMILY_GUARDS}
                  firstLabel={t(`${name}.${value}`)}
                />
              );
            })
          )}
        </div>
      )
    );
  };

  return (
    <div id="side-bar-modal-root">
      {component && (
        <BreadcrumbsBar className={styles['update-component-page__breadcrumb']}>
          {searchParams.get('origin') === 'products' && (
            <BreadcrumbItem
              text={t('components.componentForm.breadcrumb.products')}
              link="/catalog/products"></BreadcrumbItem>
          )}
          <BreadcrumbItem
            text={t('components.componentForm.breadcrumb.components')}
            link="/catalog/components"></BreadcrumbItem>
          <BreadcrumbItem
            text={t('components.componentForm.breadcrumb.update')}
            link={`/catalog/components/${component.id}/detail`}></BreadcrumbItem>
        </BreadcrumbsBar>
      )}
      <div className={styles['update-component-page__content']}>
        <section className={styles['update-component-page__content__section']}>
          <header className={styles['update-component-page__content__section__header']}>
            <div className={styles['user-detail-page__content__section__name']}>
              {isLoading && <Skeleton type="text" />}
              {component && (
                <Text
                  label={getValue(component, getLocalizedProperty('label'))}
                  size="s"
                  type="title"></Text>
              )}
            </div>
            <div className={styles['update-component-page__content__section__actions']}>
              {component && !component?.deletedAt && (
                <Button
                  className={styles['update-component-page__content__section__actions__button']}
                  label={t('action.deactivate', { ns: 'common' })}
                  onClick={() => setIsDialogOpened(true)}
                  category="secondary"
                  size={'s'}
                  isLoading={isLoadingDeactivate}
                />
              )}
              {component && (
                <Button
                  iconRight="fa-pen-to-square"
                  label={t('action.edit', { ns: 'common' })}
                  onClick={() => setIsSidebarOpened(true)}
                  category="secondary"
                  isDisabled={true}
                  size={'s'}
                />
              )}
              {component && (
                <Dialog
                  isLoading={isLoading}
                  title={t('components.datagrid.deactivate.dialog.title', {
                    component: getValue(component, getLocalizedProperty('label'))
                  })}
                  text={t('components.datagrid.deactivate.dialog.text')}
                  isOpened={isDialogOpened}
                  cancelButtonLabel={t('action.cancel', { ns: 'common' })}
                  confirmButtonLabel={t('action.deactivate', { ns: 'common' })}
                  onCancel={() => setIsDialogOpened(false)}
                  onConfirm={() => deactivate()}
                />
              )}
            </div>
          </header>
          <div className={styles['update-component-page__content__section__main']}>
            <div>
              <div className={styles['update-component-page__content__section__main__item']}>
                <Text
                  color={ColorPropsEnum.GREY}
                  label={t('components.datagrid.columns.status')}
                  className={
                    styles['update-component-page__content__section__main__item__title']
                  }></Text>
                {isLoading ? (
                  <Skeleton type="text" />
                ) : (
                  component && (
                    <Chips
                      firstLabel={t(component.deletedAt ? 'inactive' : 'active', {
                        ns: 'common'
                      })}
                      secondLabel={
                        component.deletedAt
                          ? moment(component.deletedAt).format('DD/MM/YYYY HH:mm')
                          : ''
                      }
                      color={component.deletedAt ? ColorPropsEnum.WARNING : ColorPropsEnum.SUCCESS}
                    />
                  )
                )}
              </div>
              <div className={styles['update-component-page__content__section__main__item']}>
                <Text
                  color={ColorPropsEnum.GREY}
                  label={t('components.componentForm.componentTypeInput.label')}
                  className={
                    styles['update-component-page__content__section__main__item__title']
                  }></Text>
                {isLoading ? (
                  <Skeleton type="text" />
                ) : (
                  component && (
                    <Chips
                      key={component.componentType}
                      className={
                        styles['update-component-page__content__section__main__item__chips']
                      }
                      color={ColorPropsEnum.FAMILY_GUARDS}
                      firstLabel={t(`componentTypes.${component.componentType}`)}
                    />
                  )
                )}
              </div>
              {displayDetailsElement(component?.materials, 'material')}
              {displayDetailsElement(component?.shapes, 'shape')}
              {displayDetailsElement(component?.structures, 'structure')}
              {displayDetailsElement(component?.shades, 'shade')}
              {displayDetailsElement(component?.brands, 'brand')}
              {displayDetailsElement(component?.angulations, 'angulation')}
              {displayDetailsElement(component?.aspects, 'aspect')}
              {displayEnumDetailsElement(component?.implantAttachments, 'implantAttachments')}
              {displayEnumDetailsElement(
                component?.toothStratificationTechniques,
                'toothStratificationTechniques'
              )}
            </div>
          </div>
          <SideBarModal
            title={t('components.componentForm.update.dialog.title', {
              label: getValue(component, getLocalizedProperty('label'))
            })}
            isOpened={isSidebarOpened}
            onClose={() => setIsSidebarOpened(false)}></SideBarModal>
        </section>
      </div>
    </div>
  );
};

export default ComponentDetailsPage;
