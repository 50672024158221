import { UsersReducer } from '../../models/user';

interface ReducerState {
  userState: UsersReducer;
}

export const isUserLoadingSelector = (state: ReducerState) => state.userState.isUserLoading;

export const userSelector = (state: ReducerState) => state.userState.user;

export const isLoadingCreationUserSelector = (state: ReducerState) =>
  state.userState.isLoadingCreationUser;

export const isLoadingPatchUserSelector = (state: ReducerState) =>
  state.userState.isLoadingPatchUser;
