import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getActiveCircleCadVersions } from '../../services/circle-cad-version.services';
import { circleCadVersionsReducer } from '../../models/circle-cad-version';

const initialCircleCadVersions: circleCadVersionsReducer = {
  areCircleCadVersionsLoading: false,
  circleCadVersions: []
};

export const fetchActiveCircleCadVersions = createAsyncThunk(
  '/manufacturing/circle-cad-versions',
  async () => {
    return getActiveCircleCadVersions();
  }
);

export const circleCadVersionsSlice = createSlice({
  name: 'circleCadVersionsSlice',
  initialState: initialCircleCadVersions,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<circleCadVersionsReducer>) => {
    builder
      .addCase(fetchActiveCircleCadVersions.pending, (state) => {
        state.areCircleCadVersionsLoading = true;
      })
      .addCase(fetchActiveCircleCadVersions.fulfilled, (state, actions) => {
        state.areCircleCadVersionsLoading = false;
        state.circleCadVersions = actions.payload.data;
      })
      .addCase(fetchActiveCircleCadVersions.rejected, (state) => {
        state.areCircleCadVersionsLoading = false;
      })
      .addCase('RESET_ALL', () => {
        return { ...initialCircleCadVersions };
      });
  }
});

export const circleCadVersionsActions = circleCadVersionsSlice.actions;
