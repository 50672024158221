import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCommonTypes } from '../../services/common-types.services';
import { CommonTypesReducer } from '../../models/common-types';

const initialCommonTypes: CommonTypesReducer = {
  areCommonTypesLoading: true,
  commonTypes: undefined
};

export const fetchCommonTypesAsync = createAsyncThunk(
  'common-types/load',
  async (arg, thunkAPI) => {
    try {
      const results = await getCommonTypes();
      return results.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const commonTypesSlice = createSlice({
  name: 'commonTypes',
  initialState: initialCommonTypes,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CommonTypesReducer>) => {
    builder
      .addCase(fetchCommonTypesAsync.pending, (state) => {
        state.areCommonTypesLoading = true;
      })
      .addCase(fetchCommonTypesAsync.fulfilled, (state, actions) => {
        state.areCommonTypesLoading = false;
        state.commonTypes = actions.payload;
      })
      .addCase(fetchCommonTypesAsync.rejected, (state) => {
        state.areCommonTypesLoading = false;
      })
      .addCase('RESET_ALL', () => {
        return { ...initialCommonTypes };
      });
  }
});
