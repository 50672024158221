/* eslint-disable react/prop-types */
import React from 'react';

import { Checkbox } from '@anatoscope/circlestorybook';
import styles from './checkbox-filter.module.scss';

// Not actually a filter, but used by reactdatagrid as such
// Its purpose is during product creation, when selecting all checkbox of a column
export class CheckboxColumnHeader extends React.Component {
  constructor(props) {
    super(props);

    const { filterValue } = props;

    this.state = {
      value: filterValue ? filterValue.value : ''
    };
  }

  render() {
    return this.props.render(
      <div className={styles['checkbox-column-header']}>
        <Checkbox
          isChecked={this.props.checked}
          onClick={() => {
            this.props.onHeaderCheckboxClick(this.props.headerKey);
          }}
        />
      </div>
    );
  }
}
