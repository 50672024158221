import axios, { AxiosResponse } from 'axios';
import { Establishment } from '../models/user';
import { EstablishmentType } from '../enum/establishment';

export const getClinics = (): Promise<AxiosResponse> => {
  return getEstablishments(EstablishmentType.CLINIC);
};

export const getLaboratories = (): Promise<AxiosResponse> => {
  return getEstablishments(EstablishmentType.LABORATORY);
};

export const getDesignCenters = (): Promise<AxiosResponse> => {
  return getEstablishments(EstablishmentType.DESIGN_CENTER);
};

const getEstablishments = (type: EstablishmentType) => {
  return axios.get(`${API_GCP_GATEWAY}/establishments?filter.type=${type}`);
};

export const createEstablishment = (clinic: Establishment): Promise<AxiosResponse> => {
  const url = `${API_GCP_GATEWAY}/establishments`;
  return axios.post(url, clinic);
};
