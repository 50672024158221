import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getToken, isCurrentUser } from './firebase.services';
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { backOff, BackoffOptions } from 'exponential-backoff';

const baseQuery = fetchBaseQuery({
  baseUrl: API_GCP_GATEWAY,
  prepareHeaders: async (headers: Headers) => {
    headers.set('x-api-key', API_KEY_GCP_GATEWAY);
    if (isCurrentUser()) {
      const token = await getToken();
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const exponentialBackOffBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: FetchBaseQueryError
) => {
  const backOffOptions: BackoffOptions = {
    maxDelay: 1000,
    numOfAttempts: 5,
    timeMultiple: 1
  };
  return await backOff(async () => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error && 'status' in result.error && result.error.status === 504) {
      throw new Error('504 Gateway Timeout');
    }
    return result;
  }, backOffOptions);
};

export const api = createApi({
  baseQuery: exponentialBackOffBaseQuery,
  tagTypes: ['Orders'],
  endpoints: () => ({})
});
