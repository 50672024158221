import { ComponentInProductCreationForm } from '../../../../models/product';
import i18next from 'i18next';
import { ComponentInProduct } from '../../../../models/component';

const getComponentListItemSupportingText = (
  component: ComponentInProductCreationForm | ComponentInProduct
) => {
  let supportingText;
  switch (true) {
    // One mandatory component
    case component.rule.min === 1 && component.rule.max === 1:
      supportingText = i18next.t('products.productForm.components.supportingTextOne', {
        ns: 'catalog',
        componentType: i18next.t(`componentTypes.${component.componentType}`, { ns: 'catalog' })
      });
      break;
    // One optional component
    case component.rule.min === 0 && component.rule.max === 1:
      supportingText = i18next.t('products.productForm.components.supportingTextOneOptional', {
        ns: 'catalog',
        componentType: i18next.t(`componentTypes.${component.componentType}`, { ns: 'catalog' })
      });
      break;
    // At least X components (more than one)
    case component.rule.min > 0 &&
      component.rule.max > 1 &&
      component.rule.min !== component.rule.max:
    case component.rule.min === 0 && component.rule.max > 1:
      supportingText = i18next.t('products.productForm.components.supportingTextMany', {
        componentType: i18next.t(`componentTypes.${component.componentType}`, {
          ns: 'catalog'
        }),
        ns: 'catalog',
        min: component.rule.min.toString(),
        max: component.rule.max.toString()
      });
      break;
    // Exactly X components (more than one)
    case component.rule.min > 0 &&
      component.rule.max > 1 &&
      component.rule.min === component.rule.max:
      supportingText = i18next.t('products.productForm.components.supportingTextX', {
        componentType: i18next.t(`componentTypes.${component.componentType}`, {
          ns: 'catalog'
        }),
        ns: 'catalog',
        min: component.rule.min.toString(),
        max: component.rule.max.toString()
      });
      break;
  }

  return supportingText;
};

export { getComponentListItemSupportingText };
