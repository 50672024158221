import { ProductCreationForm, ProductsReducer } from '../../models/product';
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { getAllActiveProducts, getProductById } from '../../services/products.services';

const initialProducts: ProductsReducer = {
  isProductLoading: false,
  areActiveProductsLoading: false,
  activeProducts: undefined,
  product: {
    labelFr: '',
    labelEn: '',
    family: '',
    category: '',
    class: '',
    circleCadVersion: '',
    circleCadProductType: '',
    teethMode: '',
    stumpMode: '',
    proportionalBilling: false,
    injectionMode: '',
    isChairSide: false,
    components: undefined,
    productRule: undefined,
    productCompatibilities: undefined,
    imageUrl: ''
  },
  productDetails: undefined,
  products: undefined
};

export const fetchProductByIdAsync = createAsyncThunk(
  'products/loadProductById',
  async (id: string, thunkAPI) => {
    try {
      return await getProductById(id);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchAllActiveProducts = createAsyncThunk('products/active', async () => {
  return getAllActiveProducts();
});

export const productsSlice = createSlice({
  name: 'products',
  initialState: initialProducts,
  reducers: {
    setProduct: (state, action: PayloadAction<ProductCreationForm>) => {
      state.product = action.payload;
    },
    resetProduct: (state) => {
      state.product = initialProducts.product;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<ProductsReducer>) => {
    builder
      .addCase(fetchProductByIdAsync.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(fetchProductByIdAsync.fulfilled, (state, actions) => {
        state.isProductLoading = false;
        state.productDetails = actions.payload.data;
      })
      .addCase(fetchProductByIdAsync.rejected, (state) => {
        state.isProductLoading = false;
      })
      .addCase(fetchAllActiveProducts.pending, (state) => {
        state.areActiveProductsLoading = true;
      })
      .addCase(fetchAllActiveProducts.fulfilled, (state, actions) => {
        state.areActiveProductsLoading = false;
        state.activeProducts = actions.payload.data.data;
      })
      .addCase(fetchAllActiveProducts.rejected, (state) => {
        state.areActiveProductsLoading = false;
      })
      .addCase('RESET_ALL', () => {
        return { ...initialProducts };
      });
  }
});

export const productsActions = productsSlice.actions;
