import { api } from './api';
import { UserInfos } from '../models/user';

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation<UserInfos, string>({
      query: (email) => ({
        url: `/users/${email}/reset-password`,
        method: 'POST'
      })
    })
  })
});

export const { useResetPasswordMutation } = usersApi;
