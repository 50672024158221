import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut as signOutFirebase
} from 'firebase/auth';

import { UserCallback } from '../models/user';
import getAuthFirebase from '../auth/firebase.config';

const signIn = (email: string, password: string) => {
  return signInWithEmailAndPassword(getAuthFirebase(), email, password);
};

const signInWithUserCustomToken = (customToken: string) => {
  return signInWithCustomToken(getAuthFirebase(), customToken);
};

const signOut = () => {
  return signOutFirebase(getAuthFirebase());
};

const getAuthState = (callback: UserCallback) => {
  return onAuthStateChanged(getAuthFirebase(), (userFirebase) => {
    callback(userFirebase);
  });
};

const isCurrentUser = () => {
  return !!getAuthFirebase().currentUser;
};

/*const getClaims = async () => {
  return await getAuthFirebase().currentUser?.getIdTokenResult();
};*/

const isAdminUser = async () => {
  return Promise.resolve(true);
  // TODO, remove the comment when Cypress will call the back APIs without fixtures.
  //const idTokenResult = await getClaims();
  //return !!idTokenResult?.claims?.role;
};

const getToken = async (forceRefresh = false) => {
  return await getAuthFirebase()?.currentUser?.getIdToken(forceRefresh);
};

/**
 * Force the refresh of Firebase token.
 * If we want to get the new claims, use getIdTokenResult() instead.
 */
const forceRefreshToken = () => {
  return getToken(true);
};

export {
  signIn,
  signInWithUserCustomToken,
  getAuthState,
  signOut,
  forceRefreshToken,
  getToken,
  isCurrentUser,
  isAdminUser
};
