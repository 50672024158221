import { FeedbackReducer } from '../../models/feedback';

interface ReducerState {
  feedbackState: FeedbackReducer;
}

const filedErrorSelector = (state: ReducerState) => {
  return state.feedbackState.fieldError;
};

const toastSelector = (state: ReducerState) => {
  return state.feedbackState.toast;
};

const attentionBoxErrorSelector = (state: ReducerState) => {
  return state.feedbackState.attentionBoxError;
};

export { filedErrorSelector, toastSelector, attentionBoxErrorSelector };
