import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog } from '@anatoscope/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import styles from './components-page.module.scss';
import { useNavigate } from 'react-router-dom';
import DatagridFeature, { getSort } from '../../../../features/datagrid/DatagridFeature';
import {
  getColumnSettings,
  getFilters,
  getValue,
  masterDetailsHeight,
  renderMasterDetails,
  rowClassName
} from './components';
import { useTranslation } from 'react-i18next';
import { componentsActions } from '../../../../store/components/components.reducers';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { getMessageError } from '../../../../utils/utils';
import { Component } from '../../../../models/component';
import { FilterReducer, LoadDataProps, SettingsReducer } from '../../../../models/datagrid';
import {
  deactivateComponent,
  getPaginatedComponents
} from '../../../../services/components.services';

import {
  areCommonTypesLoadingSelector,
  commonTypesSelector
} from '../../../../store/common-types/common-types.selectors';
import { datagridSettingsActions } from '../../../../store/datagrids-settings/datagrids-settings.reducers';
import { componentsDatagridStateSelector } from '../../../../store/datagrids-settings/datagrids-settings.selectors';
import { getLocalizedProperty } from '../catalog';
import { TypeSingleSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { ToastType } from '../../../../enum/feedback';

const ComponentsPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['catalog']);

  const commonTypes = useAppSelector(commonTypesSelector);
  const areCommonTypesLoading = useAppSelector(areCommonTypesLoadingSelector);
  const datagridSettings: SettingsReducer = useAppSelector(componentsDatagridStateSelector);
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [isLoadingDeactivate, setIsLoadingComponent] = useState<boolean>(false);
  const [component, setComponent] = useState<Component>();

  const loadData = ({ skip, limit, sortInfo, filterValue }: LoadDataProps) => {
    const page = skip >= limit ? skip / limit + 1 : 1;
    const filters = getFilters(filterValue);
    const sort = getSort(sortInfo);

    return getPaginatedComponents(page, limit, filters, sort)
      .then((components) => {
        return {
          data: components.data.data.map((component: Component) => {
            // Add brandTypes in order to find brand name with brand id in row details
            return { ...component, brandTypes: commonTypes?.brands };
          }),
          count: components.data.meta.totalItems
        };
      })
      .catch(() => {
        return { data: [], count: 0 };
      });
  };

  const dataSource = useCallback(loadData, [commonTypes, isLoadingDeactivate]);

  const handleEscKey = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsDialogOpened(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);
    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [handleEscKey]);

  const deactivateCallback = async (component: Component) => {
    setComponent(component);
    setIsDialogOpened(true);
  };
  const deactivate = async () => {
    setIsDialogOpened(false);
    setIsLoadingComponent(true);
    if (component) {
      deactivateComponent(component)
        .then(() => {
          dispatch(
            feedbackActions.setToast({
              message: t('components.datagrid.deactivate.deactivated'),
              type: ToastType.SUCCESS
            })
          );
          setComponent(undefined);
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        )
        .finally(() => {
          setIsLoadingComponent(false);
        });
    }
  };

  const onFilterValueChange = (newFilterValue: FilterReducer[]) => {
    dispatch(datagridSettingsActions.setComponentsDatagridFilters(newFilterValue));
  };

  const onSortInfoChange = (newSortInfo: TypeSingleSortInfo) => {
    dispatch(
      datagridSettingsActions.setComponentsDatagridSort({
        name: newSortInfo?.name,
        dir: newSortInfo?.dir
      })
    );
  };

  return (
    <div>
      {component && (
        <Dialog
          title={t('components.datagrid.deactivate.dialog.title', {
            component: getValue(component, getLocalizedProperty('label'))
          })}
          text={t('components.datagrid.deactivate.dialog.text')}
          isOpened={isDialogOpened}
          cancelButtonLabel={t('action.cancel', { ns: 'common' })}
          confirmButtonLabel={t('action.deactivate', { ns: 'common' })}
          onCancel={() => setIsDialogOpened(false)}
          isLoading={isLoadingDeactivate}
          onConfirm={() => deactivate()}
        />
      )}
      <div className={styles['components-page__actions']}>
        <Button
          category="tertiary"
          label={t('datagrid.removeAllFilters', { ns: 'common' })}
          onClick={() => {
            dispatch(datagridSettingsActions.resetComponentsDatagridFilters());
          }}
        />
        <Button
          iconRight="plus"
          label={t('components.datagrid.actions.createComponent', { ns: 'catalog' })}
          onClick={() => {
            dispatch(componentsActions.setComponent(undefined));
            navigate('/catalog/components/create');
          }}
        />
      </div>
      {!areCommonTypesLoading && (
        <DatagridFeature
          key="components"
          style={{ minHeight: 'calc(100dvh - 14.5rem)' }}
          columns={getColumnSettings(commonTypes, deactivateCallback)}
          filterValue={datagridSettings.filters}
          onFilterValueChange={onFilterValueChange}
          sortInfo={datagridSettings.sort}
          onSortInfoChange={onSortInfoChange}
          dataSource={dataSource}
          renderRowDetails={renderMasterDetails}
          multiRowExpand={false}
          rowExpandHeight={masterDetailsHeight}
          rowClassName={rowClassName}
        />
      )}
    </div>
  );
};

export default ComponentsPage;
