import { AuthenticationReducer } from '../../models/user';

interface ReducerState {
  authenticationState: AuthenticationReducer;
}

const userInfoSelector = (state: ReducerState) => state.authenticationState.userInfos;

const isAuthenticatedSelector = (state: ReducerState) => state.authenticationState.isAuthenticated;

export { userInfoSelector, isAuthenticatedSelector };
