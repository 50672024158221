import { DatagridsSettingsReducer } from '../../models/datagrid';

interface ReducerState {
  datagridsSettingsState: DatagridsSettingsReducer;
}
export const usersDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.users;

export const productsDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.products;

export const componentsDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.components;

export const ordersDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.orders;

export const compatibilitiesDatagridStateSelector = (state: ReducerState) =>
  state.datagridsSettingsState.compatibilities;
