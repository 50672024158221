import { createSelector } from '@reduxjs/toolkit';
import { componentSelector } from '../components/components.selectors';
import i18n from '../../i18n';
import {
  Angulation,
  Aspect,
  Brand,
  BrandReference,
  CommonTypesReducer,
  Material,
  Shade,
  Shape,
  Structure,
  SelectorItem
} from '../../models/common-types';
import { StringObject } from '../../models/common';
import { ComponentType } from '../../enum/component-type';

interface ReducerState {
  commonTypeState: CommonTypesReducer;
}

export const areCommonTypesLoadingSelector = (state: ReducerState) =>
  state.commonTypeState.areCommonTypesLoading;

export const commonTypesSelector = (state: ReducerState) => state.commonTypeState.commonTypes;

export const componentTypesSelector = createSelector(commonTypesSelector, (commonTypes) => {
  if (commonTypes && 'componentTypes' in commonTypes) {
    return commonTypes.componentTypes;
  }
});

export const componentTypeDropdownSelector = createSelector(
  componentTypesSelector,
  (componentTypes) => {
    if (componentTypes) {
      return Object.keys(componentTypes)
        .map((type) => {
          return { label: i18n.t(`componentTypes.${type}`, { ns: 'catalog' }), value: type };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    }
    return [];
  }
);

export const materialsSelector = createSelector(commonTypesSelector, (commonTypes): Material[] => {
  if (commonTypes && 'materials' in commonTypes) {
    return commonTypes.materials;
  }
  return [];
});

export const allowedMaterialsCheckboxSelector = createSelector(
  [materialsSelector, componentSelector],
  (materials, component) => {
    return materials
      .filter((material: Material) => material.componentType === component.componentType)
      .map((material: Material): SelectorItem => {
        let materialLabel: string = i18n.t(`materials.${material.code}`, { ns: 'catalog' });

        if (material.stratification) {
          const materialStratification = i18n.t(
            `materials.stratification.${material.stratification}`,
            {
              ns: 'catalog'
            }
          );
          materialLabel += ` ${materialStratification}`;
        }

        return {
          isChecked: true,
          value: material.id,
          label: materialLabel
        };
      })
      .sort((a: SelectorItem, b: SelectorItem) => {
        // Sort alphabetically by label
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
  }
);

export const shadesSelector = createSelector(commonTypesSelector, (commonTypes): Shade[] => {
  if (commonTypes && 'shades' in commonTypes) {
    return commonTypes.shades;
  }
  return [];
});

/**
 * This selector is used to create a dropdown for shades.
 * It groups the shades by the first letter of the shade code.
 * If the component type is TOOTH, it groups the shades by the first letter of the shade code and sorts them alphabetically.
 * @param shades
 * @param componentTypes
 * @param component
 * @returns {SelectorItem[][]} - An array of arrays of SelectorItem
 */

export const allowedShadesDropdownSelector = createSelector(
  [shadesSelector, componentTypesSelector, componentSelector],
  (shades, componentTypes, component) => {
    const allowedShades: SelectorItem[] = shades
      .filter((shade: Shade) => shade.componentType === component.componentType)
      .map((shade: Shade) => {
        return {
          isChecked: true,
          value: shade.id,
          label: shade.code
        };
      });

    // If there is at least one allowed shade
    if (allowedShades.length > 0) {
      // If the component type is TOOTH, group the shades by the first letter of the label
      if (component.componentType === componentTypes.TOOTH) {
        // Sort the array by label
        allowedShades.sort((a: SelectorItem, b: SelectorItem) => a.label.localeCompare(b.label));

        // Group by the first letter of the label
        const groupedShades = allowedShades.reduce(
          (acc: { [key: string]: SelectorItem[] }, shade: SelectorItem) => {
            const firstLetter = shade.label.charAt(0).toUpperCase();
            if (!acc[firstLetter]) {
              acc[firstLetter] = [];
            }
            acc[firstLetter].push({ isChecked: true, ...shade });
            return acc;
          },
          {}
        );

        // Convert grouped object to array of arrays
        return Object.values(groupedShades);
      }
      return [allowedShades];
    }
    return [];
  }
);

export const shapesSelector = createSelector(commonTypesSelector, (commonTypes): Shape[] => {
  if (commonTypes && 'shapes' in commonTypes) {
    return commonTypes.shapes;
  }
  return [];
});

export const allowedShapesCheckboxSelector = createSelector(
  [shapesSelector, componentSelector],
  (shapes, component) => {
    return shapes
      .filter((shape: Shape) => shape.componentType === component.componentType)
      .map((shape: Shape) => {
        return {
          isChecked: true,
          value: shape.id,
          label: i18n.t(`shapes.${shape.code}`, { ns: 'catalog' })
        };
      });
  }
);

export const structuresSelector = createSelector(
  commonTypesSelector,
  (commonTypes): Structure[] => {
    if (commonTypes && 'structures' in commonTypes) {
      return commonTypes.structures;
    }
    return [];
  }
);

export const allowedStructuresCheckboxSelector = createSelector(
  [structuresSelector, componentSelector],
  (structures, component) => {
    return structures
      .filter((structure: Structure) => structure.componentType === component.componentType)
      .map((structure: Structure) => {
        return {
          isChecked: true,
          value: structure.id,
          label: i18n.t(`structures.${structure.code}`, { ns: 'catalog' })
        };
      });
  }
);

export const angulationsSelector = createSelector(
  commonTypesSelector,
  (commonTypes): Angulation[] => {
    if (commonTypes && 'angulations' in commonTypes) {
      return commonTypes.angulations;
    }
    return [];
  }
);

export const allowedAngulationsCheckboxSelector = createSelector(
  [angulationsSelector, componentSelector],
  (angulations, component) => {
    return angulations
      .filter((angulation: Angulation) => angulation.componentType === component.componentType)
      .map((angulation: Angulation) => {
        return {
          isChecked: true,
          value: angulation.id,
          label: i18n.t(`angulations.${angulation.code}`, { ns: 'catalog' })
        };
      });
  }
);

export const aspectsSelector = createSelector(commonTypesSelector, (commonTypes): Aspect[] => {
  if (commonTypes && 'aspects' in commonTypes) {
    return commonTypes.aspects;
  }
  return [];
});

export const allowedAspectsCheckboxSelector = createSelector(
  [aspectsSelector, componentSelector],
  (aspects, component) => {
    return aspects
      .filter((aspect: Aspect) => aspect.componentType === component.componentType)
      .map((aspect: Aspect) => {
        return {
          isChecked: true,
          value: aspect.id,
          label: i18n.t(`aspects.${aspect.code}`, { ns: 'catalog' })
        };
      });
  }
);

export const brandsSelector = createSelector(commonTypesSelector, (commonTypes): Brand[] => {
  if (commonTypes && 'brands' in commonTypes) {
    return commonTypes.brands;
  }
  return [];
});

export const allowedBrandsDropdownSelector = createSelector(
  [brandsSelector, componentSelector],
  (brands, component) => {
    const allowedReferences = brands
      .filter(
        (brand: Brand) =>
          brand.brandReferences.filter(
            (reference: BrandReference) => reference.componentType === component.componentType
          ).length > 0
      )
      .map((brand: Brand) => {
        return {
          isChecked: true,
          value: brand.id,
          label: brand.brandName
        };
      });

    if (allowedReferences.length > 0) {
      return [allowedReferences];
    }
    return [];
  }
);

export const familiesSelector = createSelector(commonTypesSelector, (commonTypes) => {
  if (commonTypes && 'families' in commonTypes) {
    return commonTypes.families;
  }
  return [];
});

export const familyDropdownSelector = createSelector(familiesSelector, (families) => {
  if (families) {
    return Object.values(families)
      .map((family) => {
        return { label: i18n.t(`products.families.${family}`, { ns: 'catalog' }), value: family };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }
  return [];
});

export const productClassesSelector = createSelector(commonTypesSelector, (commonTypes) => {
  if (commonTypes && 'productClasses' in commonTypes) {
    return commonTypes.productClasses;
  }
  return [];
});

export const productClassesDropdownSelector = createSelector(productClassesSelector, (classes) => {
  if (classes) {
    return Object.values(classes)
      .map((productClass) => {
        return {
          label: i18n.t(`products.classes.${productClass}`, { ns: 'catalog' }),
          value: productClass
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }
  return [];
});

export const productCategoriesSelector = createSelector(commonTypesSelector, (commonTypes) => {
  if (commonTypes && 'productCategories' in commonTypes) {
    return commonTypes.productCategories;
  }
  return [];
});

export const productCategoriesDropdownSelector = createSelector(
  productCategoriesSelector,
  (categories) => {
    if (categories) {
      return Object.values(categories)
        .map((category) => {
          return {
            label: i18n.t(`products.categories.${category}`, { ns: 'catalog' }),
            value: category
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
    }
    return [];
  }
);

export const productSubCategoriesSelector = createSelector(commonTypesSelector, (commonTypes) => {
  if (commonTypes && 'productSubCategories' in commonTypes) {
    return commonTypes.productSubCategories;
  }
  return [];
});

export const productSubCategoriesDropdownSelector = createSelector(
  productSubCategoriesSelector,
  (subCategories) => {
    if (subCategories) {
      // Make sure subCategories always stay in a relevant order
      const orderedSubCategories = {
        WITH_WEDGE: 0,
        WITHOUT_WEDGE: 1,
        NOT_MONOLITHIC: 2,
        MONOLITHIC_ESTHETIC: 3,
        MONOLITHIC_MONOSHADE: 4,
        WITH_RESERVOIR: 5,
        WITHOUT_RESERVOIR: 6
      };
      return Object.values(subCategories)
        .map((subCategory) => {
          return {
            label: i18n.t(`products.subCategories.${subCategory}`, { ns: 'catalog' }),
            value: subCategory,
            isChecked: false
          };
        })
        .sort((a, b) => {
          const orderA = orderedSubCategories[a.value as keyof typeof orderedSubCategories];
          const orderB = orderedSubCategories[b.value as keyof typeof orderedSubCategories];

          if (orderA > orderB) {
            return 1;
          }
          if (orderA < orderB) {
            return -1;
          }
          return 0;
        });
    }
    return [];
  }
);

export const teethModesSelector = createSelector(commonTypesSelector, (commonTypes) => {
  if (commonTypes && 'teethModes' in commonTypes) {
    return commonTypes.teethModes;
  }
  return [];
});

export const teethModesDropdownSelector = createSelector(teethModesSelector, (teethModes) => {
  if (teethModes) {
    // Manually arrange teethModes in the closest thing we have to an ascending order
    const orderedTeethModes = {
      'single-tooth': 0,
      'multi-teeth': 1,
      'single-range': 2,
      'multi-range': 3,
      'full-arch': 4,
      'multi-arch': 5
    };

    return Object.values(teethModes)
      .map((teethMode) => {
        return {
          label: i18n.t(`teethModes.${teethMode}`, { ns: 'catalog' }),
          value: teethMode,
          isChecked: false
        };
      })
      .sort((a, b) => {
        if (
          orderedTeethModes[a.value as keyof typeof orderedTeethModes] >
          orderedTeethModes[b.value as keyof typeof orderedTeethModes]
        ) {
          return 1;
        }
        if (
          orderedTeethModes[a.value as keyof typeof orderedTeethModes] <
          orderedTeethModes[b.value as keyof typeof orderedTeethModes]
        ) {
          return -1;
        }
        return 0;
      });
  }
  return [];
});

export const stumpModesSelector = createSelector(commonTypesSelector, (commonTypes) => {
  if (commonTypes && 'stumpModes' in commonTypes) {
    return commonTypes.stumpModes;
  }
  return [];
});

export const stumpModesDropdownSelector = createSelector(stumpModesSelector, (stumpModes) => {
  if (stumpModes) {
    // Manually arrange stumpMode in the closest thing we have to an ascending order
    const orderedStumpModes = {
      none: 0,
      selectable: 1,
      always: 2,
      only: 3
    };
    return Object.values(stumpModes)
      .map((stumpMode) => {
        return {
          label: i18n.t(`stumpModes.${stumpMode}`, { ns: 'catalog' }),
          value: stumpMode
        };
      })
      .sort((a, b) => {
        if (
          orderedStumpModes[a.value as keyof typeof orderedStumpModes] <
          orderedStumpModes[b.value as keyof typeof orderedStumpModes]
        ) {
          return -1;
        }
        if (
          orderedStumpModes[a.value as keyof typeof orderedStumpModes] >
          orderedStumpModes[b.value as keyof typeof orderedStumpModes]
        ) {
          return 1;
        }
        return 0;
      });
  }
  return [];
});

export const injectionModesSelector = createSelector(commonTypesSelector, (commonTypes) => {
  if (commonTypes && 'injectionModes' in commonTypes) {
    return commonTypes.injectionModes;
  }
  return [];
});

export const toothStratificationTechniquesSelector = createSelector(
  commonTypesSelector,
  (commonTypes): StringObject => {
    if (commonTypes && 'toothStratificationTechniques' in commonTypes) {
      return commonTypes.toothStratificationTechniques;
    }
    return {};
  }
);

export const allowedToothStratificationTechniquesCheckboxSelector = createSelector(
  [toothStratificationTechniquesSelector, componentSelector],
  (toothStratificationTechniques, component) => {
    return component.componentType !== ComponentType.TOOTH
      ? []
      : Object.values(toothStratificationTechniques).map((toothStratificationTechnique: string) => {
          return {
            isChecked: false,
            value: toothStratificationTechnique,
            label: i18n.t(`toothStratificationTechniques.${toothStratificationTechnique}`, {
              ns: 'catalog'
            })
          };
        });
  }
);

export const implantAttachmentsSelector = createSelector(
  commonTypesSelector,
  (commonTypes): StringObject => {
    if (commonTypes && 'implantAttachments' in commonTypes) {
      return commonTypes.implantAttachments;
    }
    return {};
  }
);

export const allowedImplantAttachmentsCheckboxSelector = createSelector(
  [implantAttachmentsSelector, componentSelector],
  (implantAttachments, component) => {
    return component.componentType !== ComponentType.IMPLANT_ATTACHMENT
      ? []
      : Object.values(implantAttachments).map((implantAttachment: string) => {
          return {
            isChecked: true,
            value: implantAttachment,
            label: i18n.t(`implantAttachments.${implantAttachment}`, {
              ns: 'catalog'
            })
          };
        });
  }
);
