/* global FIREBASE_CONFIG_API_ID, FIREBASE_CONFIG */

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const initFirebase = () => {
  const defaultConfig: object = {
    apiKey: 'XXXXXX',
    authDomain: 'XXXXXX',
    projectId: 'XXXXX',
    storageBucket: 'XXXXXX',
    messagingSenderId: 'XXXXXXX'
  };
  const config: object =
    typeof FIREBASE_CONFIG !== 'undefined' ? (FIREBASE_CONFIG as unknown as object) : defaultConfig;

  const firebaseConfig = { ...config, ...{ appId: FIREBASE_CONFIG_API_ID } };
  // Initialize Firebase
  return initializeApp(firebaseConfig);
};

const getAuthFirebase = () => {
  const app = initFirebase();
  return getAuth(app);
};

export default getAuthFirebase;
