import axios, { AxiosResponse } from 'axios';
import { ProductLabActivation } from '../models/product';
import { getLocalizedProperty } from '../pages/private/catalog-page/catalog';

export const getProducts = (
  page: number,
  limit: number,
  filters: string,
  sort: string
): Promise<AxiosResponse> => {
  return axios.get(`${API_GCP_GATEWAY}/products?page=${page}&limit=${limit}${filters}${sort}`);
};

// this has to stay non paginated (limit=0)
export const getAllActiveProducts = (filters = '', sort?: string): Promise<AxiosResponse> => {
  if (!sort) sort = `&sortBy=${getLocalizedProperty('label')}:ASC`;
  return axios.get(
    `${API_GCP_GATEWAY}/products?page=1&limit=0&filter.deletedAt=$null${filters}${sort}`
  );
};

export const createProduct = (product: object): Promise<AxiosResponse> => {
  return axios.post(`${API_GCP_GATEWAY}/products`, product);
};

export const activateProductForLab = (
  productId: number,
  activation: ProductLabActivation
): Promise<AxiosResponse> => {
  return axios.post(`${API_GCP_GATEWAY}/products/${productId}/labs`, activation);
};

export const getLabsActivatedToProduct = (productId: number): Promise<AxiosResponse> => {
  return axios.get(`${API_GCP_GATEWAY}/products/${productId}/labs`);
};

export const getProductById = (id: string): Promise<AxiosResponse> => {
  return axios.get(`${API_GCP_GATEWAY}/products/${id}`);
};
