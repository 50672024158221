import { api } from './api';
import { QueryParams, ServicePaginatedResponse } from '../models/datagrid';

const ordersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<ServicePaginatedResponse, QueryParams>({
      query: (arg) => {
        const { page, limit, filters, sort } = arg;
        return `/orders?page=${page}&filter.deletedAt=$null&filter.items.deletedAt=$null&limit=${limit}${filters}${sort}`;
      }
    })
  })
});

export const { useLazyGetOrdersQuery } = ordersApi;
