import React from 'react';
import { useParams } from 'react-router-dom';
import UserDetails from '../../../../features/user-details/UserDetails';

const UserDetailPage = () => {
  const { email } = useParams();

  return <UserDetails isReadOnly={true} email={email} />;
};

export default UserDetailPage;
