import axios, { AxiosResponse } from 'axios';
import { Component, ComponentDetails, NewComponent } from '../models/component';
import { getLocalizedProperty } from '../pages/private/catalog-page/catalog';

export const getPaginatedComponents = (
  page: number,
  limit: number,
  filters: string,
  sort: string
): Promise<AxiosResponse> => {
  return axios.get(`${API_GCP_GATEWAY}/components?page=${page}&limit=${limit}${filters}${sort}`);
};

// this has to stay non paginated (limit=0)
export const getAllActiveComponents = (): Promise<AxiosResponse> => {
  return axios.get(
    `${API_GCP_GATEWAY}/components?page=1&limit=0&filter.deletedAt=$null&sortBy=${getLocalizedProperty(
      'label'
    )}:ASC`
  );
};

export const getComponentById = (id: string): Promise<AxiosResponse> => {
  return axios.get(`${API_GCP_GATEWAY}/components/${id}`);
};

export const deactivateComponent = (
  component: Component | ComponentDetails
): Promise<AxiosResponse> => {
  const url = `${API_GCP_GATEWAY}/components/${component.id}`;
  return axios.delete(url);
};

export const createComponent = (component: NewComponent): Promise<AxiosResponse> => {
  const url = `${API_GCP_GATEWAY}/components`;
  return axios.post(url, component);
};
