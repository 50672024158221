import { Product } from './product';
import { ComponentRowDetails } from './component';
import { TypeSortInfo } from '@inovua/reactdatagrid-community/types/TypeSortInfo';
import { TypeColWithNameProperty } from '@inovua/reactdatagrid-community/types/TypeColumn';

interface MenuEntry {
  id?: string;
  label: string;
  type: string;
  disabled?: boolean;
  link?: string;
  onClick?: () => void;
}

interface LoadDataProps {
  skip: number;
  limit: number;
  sortInfo: SortReducer | SortReducer[];
  filterValue?: Array<FilterProps>;
}

interface MasterDetailsProps {
  data: Product | ComponentRowDetails;
}

interface FilterProps {
  name: string;
  type: string;
  operator: string;
  value?: string | Array<string>;
  active: boolean;
}

interface SortReducer {
  name: string;
  dir: SortDirection;
}

interface FilterReducer {
  name: string;
  operator: string;
  type: string;
  value: string;
}

interface SettingsReducer {
  filters: FilterReducer[];
  sort?: TypeSortInfo;
}

interface DatagridsSettingsReducer {
  users: SettingsReducer;
  products: SettingsReducer;
  components: SettingsReducer;
  orders: SettingsReducer;
  compatibilities: SettingsReducer;
}

enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  ASC_AS_NB = 1,
  DESC_AS_NB = -1
}
interface QueryParams {
  page: number;
  limit: number;
  filters: string;
  sort: string;
}

interface ServicePaginatedResponse {
  data: object[];
  links: object;
  meta: {
    currentPage: number;
    filter: object;
    itemsPerPage: number;
    sortBy: Array<Array<string>>;
    totalItems: number;
    totalPages: number;
  };
}

type TypeColWithNamePropertyPlatform = TypeColWithNameProperty & {
  cellDOMProps?: object | undefined;
  showColumnMenuTool?: boolean;
  textEllipsis?: boolean;
  showColumnMenuToolOnHover?: boolean;
  enableColumnFilterContextMenu?: boolean;
};

export {
  MenuEntry,
  LoadDataProps,
  FilterProps,
  MasterDetailsProps,
  SortDirection,
  SortReducer,
  FilterReducer,
  SettingsReducer,
  DatagridsSettingsReducer,
  TypeColWithNamePropertyPlatform,
  QueryParams,
  ServicePaginatedResponse
};
