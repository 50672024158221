import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedbackReducer, Error, Toast } from '../../models/feedback';

/**
 * https://gitlab.com/groups/anatoscope/circle/dev/platform/-/wikis/Functional-errors-management
 */

const initialFeedback: FeedbackReducer = {
  attentionBoxError: null,
  toast: null,
  fieldError: null
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: initialFeedback,
  reducers: {
    setAttentionBoxError: (state, action: PayloadAction<Error>) => {
      state.attentionBoxError = action.payload;
    },
    setToast: (state, action: PayloadAction<Toast>) => {
      state.toast = action.payload;
    },
    resetAttentionBoxError: (state) => {
      state.attentionBoxError = null;
    },
    resetToast: (state) => {
      state.toast = null;
    },
    resetAllErrors: (state) => {
      state.attentionBoxError = null;
      state.toast = null;
      state.fieldError = null;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<FeedbackReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialFeedback };
    });
  }
});

const feedbackActions = feedbackSlice.actions;

export { feedbackActions, feedbackSlice };
