import React from 'react';
import styles from '../../create-user-page.module.scss';
import { Button, Fieldset, RadioList } from '@anatoscope/circlestorybook';
import i18next from 'i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { userSelector } from '../../../../../../store/users/users.selectors';
import { usersActions } from '../../../../../../store/users/users.reducers';
import { Language, DentalNotation } from '../../../../../../enum/user';
import { dentalNotations, languages } from '../../../../../../utils/utils';
import useForm from '../../../../../../utils/useForm';

type Props = {
  nextCallback: () => void;
  previousCallback: () => void;
};

const CustomizationForm = ({ nextCallback, previousCallback }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);

  const saveCustomizationInfo = () => {
    dispatch(
      usersActions.setUser({
        ...user,
        ...values
      })
    );
  };

  const onSubmit = () => {
    saveCustomizationInfo();
    nextCallback();
  };

  const handlePreviousButton = () => {
    saveCustomizationInfo();
    previousCallback();
  };

  const { values, handleSubmit, handleSelect } = useForm(
    {
      dentalNotation: user?.dentalNotation ? user.dentalNotation : DentalNotation.ISO,
      language: user?.language ? user.language : Language.fr_FR
    },
    onSubmit
  );

  return (
    <>
      <form onSubmit={handleSubmit} className={styles['create-user-form__form']}>
        <Fieldset size="m" className={styles['create-user-form__form__fieldset']}>
          <>
            <RadioList
              title={i18next.t('userForm.dentalNotation', { ns: 'user' })}
              options={dentalNotations}
              name="dentalNotation"
              selectedValue={values.dentalNotation}
              onClick={(newValue: DentalNotation) => handleSelect(newValue, 'dentalNotation')}
              className={styles['create-user-form__form__input']}
            />
            <RadioList
              title={i18next.t('userForm.language', { ns: 'user' })}
              options={languages}
              name="language"
              selectedValue={values.language}
              onClick={(newValue: Language) => handleSelect(newValue, 'language')}
            />
          </>
        </Fieldset>
        <div className="form__buttons">
          <Button
            label={i18next.t('action.previous', { ns: 'common' })}
            category="secondary"
            onClick={handlePreviousButton}
            iconLeft="fa-chevron-left"
          />
          <Button
            label={i18next.t('action.next', { ns: 'common' })}
            type="submit"
            iconRight="fa-chevron-right"
          />
        </div>
      </form>
    </>
  );
};
export default CustomizationForm;
