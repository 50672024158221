import { NavigateFunction } from 'react-router';
import { Location } from '@remix-run/router';

export interface ActiveTab {
  page: string;
  activeTabId: number;
}

interface TabsType {
  label: string;
  disabled: boolean;
  to: string | undefined;
}

interface History {
  navigate: NavigateFunction;
  location: Location;
}

interface Step {
  id: number;
  title: string;
}

// custom history object to allow navigation outside react components
const history: History = {
  navigate: null,
  location: null
};

export { TabsType, Step, history };
