import axios from 'axios';

/**
 * Add additional data in firebase token with MS-Users.
 * This data must be only concerned security and access control.
 *
 * @param email
 * @param idToken
 *
 * @return new custom token
 */
export const setClaims = (email: string, idToken: string): Promise<axios.AxiosResponse<string>> => {
  const url = `${API_GCP_GATEWAY}/users/${email}/claims`;
  return axios.post(url, { idToken });
};
