import { Component, ComponentsReducer } from '../../models/component';
import { createSelector } from '@reduxjs/toolkit';
import { getLocalizedProperty } from '../../pages/private/catalog-page/catalog';
import { DropdownItem } from '../../models/form';
import { ComponentType } from '../../enum/component-type';

interface ReducerState {
  componentState: ComponentsReducer;
}
export const componentSelector = (state: ReducerState) => state.componentState?.component;

export const componentDetailSelector = (state: ReducerState) =>
  state.componentState?.componentDetails;

export const isComponentLoadingSelector = (state: ReducerState) =>
  state.componentState.isComponentLoading;

export const areActiveComponentsLoadingSelector = (state: ReducerState) =>
  state.componentState.activeComponentAreLoading;

export const activeComponentsSelector = (state: ReducerState) =>
  state.componentState.activeComponents;

const filterComponentsByType = (
  components: Component[],
  componentType: ComponentType
): DropdownItem[] => {
  return components
    .filter((thisComponent: Component) => thisComponent.componentType === componentType)
    .map((thisComponent: Component): DropdownItem => {
      return {
        value: thisComponent.id,
        label: thisComponent[getLocalizedProperty('label') as keyof Component] as string
      };
    });
};

export const activeFrameComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents ? filterComponentsByType(activeComponents, ComponentType.FRAME) : [];
  }
);

export const activeToothComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents ? filterComponentsByType(activeComponents, ComponentType.TOOTH) : [];
  }
);

export const activeGingivaComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents ? filterComponentsByType(activeComponents, ComponentType.GINGIVA) : [];
  }
);

export const activeInfrastructureComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents
      ? filterComponentsByType(activeComponents, ComponentType.INFRASTRUCTURE)
      : [];
  }
);

export const activeProstheticStumpComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents
      ? filterComponentsByType(activeComponents, ComponentType.PROSTHETIC_STUMP)
      : [];
  }
);

export const activeImplantAttachmentComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents
      ? filterComponentsByType(activeComponents, ComponentType.IMPLANT_ATTACHMENT)
      : [];
  }
);

export const activeWaxOnHardBaseComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents
      ? filterComponentsByType(activeComponents, ComponentType.WAX_ON_HARD_BASE)
      : [];
  }
);

export const activeImplantSystemComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents
      ? filterComponentsByType(activeComponents, ComponentType.IMPLANT_SYSTEM)
      : [];
  }
);

export const activeGuardComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents ? filterComponentsByType(activeComponents, ComponentType.GUARD) : [];
  }
);

export const activeInlayComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents ? filterComponentsByType(activeComponents, ComponentType.INLAY) : [];
  }
);
export const activeOnlayComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents ? filterComponentsByType(activeComponents, ComponentType.ONLAY) : [];
  }
);
export const activeOverlayComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents ? filterComponentsByType(activeComponents, ComponentType.OVERLAY) : [];
  }
);

export const activeModelComponentsDropdownSelector = createSelector(
  activeComponentsSelector,
  (activeComponents) => {
    return activeComponents ? filterComponentsByType(activeComponents, ComponentType.MODELS) : [];
  }
);
