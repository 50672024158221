import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { createUser, getUserByEmail, patchUser } from '../../services/users.services';
import { UserInfos, UsersReducer } from '../../models/user';
import { capitalize } from '../../utils/utils';

const initialUsers: UsersReducer = {
  user: undefined,
  isUserLoading: true,
  tags: {},
  isLoadingTags: false,
  isLoadingCreationUser: false,
  isLoadingPatchUser: false
};

const fetchUserByEmailAsync = createAsyncThunk(
  'users/loadUserByEmail',
  async (email: string, thunkAPI) => {
    try {
      const result = await getUserByEmail(email);
      return result && mapUser(result.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const createUserAsync = createAsyncThunk('users/create', async (user: UserInfos, thunkAPI) => {
  try {
    await createUser(user);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

const patchUserAsync = createAsyncThunk(
  'users/patch',
  async (user: Partial<UserInfos>, thunkAPI) => {
    const { email, ...fields } = user;
    try {
      await patchUser(fields, email);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState: initialUsers,
  reducers: {
    setUser: (state, action: PayloadAction<UserInfos>) => {
      state.user = action.payload;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<UsersReducer>) => {
    builder
      .addCase(fetchUserByEmailAsync.pending, (state) => {
        state.isUserLoading = true;
      })
      .addCase(fetchUserByEmailAsync.fulfilled, (state, actions) => {
        state.isUserLoading = false;
        state.user = actions.payload;
      })
      .addCase(fetchUserByEmailAsync.rejected, (state) => {
        state.isUserLoading = false;
      })
      .addCase(createUserAsync.pending, (state) => {
        state.isLoadingCreationUser = true;
      })
      .addCase(createUserAsync.fulfilled, (state) => {
        state.isLoadingCreationUser = false;
      })
      .addCase(createUserAsync.rejected, (state) => {
        state.isLoadingCreationUser = false;
      })
      .addCase(patchUserAsync.pending, (state) => {
        state.isLoadingPatchUser = true;
      })
      .addCase(patchUserAsync.fulfilled, (state) => {
        state.isLoadingPatchUser = false;
      })
      .addCase(patchUserAsync.rejected, (state) => {
        state.isLoadingPatchUser = false;
      })
      .addCase('RESET_ALL', () => {
        return { ...initialUsers };
      });
  }
});

const mapUser = (user: UserInfos): UserInfos => {
  return {
    ...user,
    firstName: capitalize(user.firstName),
    lastName: capitalize(user.lastName)
  };
};

const usersActions = usersSlice.actions;

export { fetchUserByEmailAsync, usersSlice, usersActions, createUserAsync, patchUserAsync };
